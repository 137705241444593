<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Pessoas físicas</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control float-left border-right-0"
          v-on:keyup.enter="onSearch"
          v-model="searchText" placeholder="Pesquisar">
          <span class="input-group-append" @click="onSearch">
              <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
      <div class="col" v-if="permissoes > 1">
        <router-link to="funcionarios/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :data="filteredList"
          :columns="columns"
          :itemsPerPage="10">
          <template v-slot:row="{ row }">
            <td>
              {{row.nome}}
            </td>
            <td>
              {{row.cpf}}
            </td>
            <td class="float-right">
              <i class="fas fa-edit mr-3 btn btn-warning " @click="editRow(row.id)" v-if="permissoes > 1"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" @click="deleteRow(row)" v-if="permissoes > 2"></i>
            </td>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import MyTable from './../../uicomponents/MyTable'
import funcionarioRepository from './../../services/api/funcionariosRepository'
export default {
  name: 'Funcionarios',
  components: {
    MyTable
  },
  data () {
    return {
      isLoading: false,
      searchText: '',
      funcionarios: [],
      //filteredData: [],
      filteredList: [],
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Cpf',
          field: 'cpf',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      permissoes: 0
    }
  },
  created () {
    this.permissoes = this.$store.getters.permissoes['Pessoa Física']['Pessoa física'] ?? 0
    if(this.permissoes > 0) {
      this.fetch()
    }
  },
  methods: {
    fetch (search = null) {
      this.isLoading = true
      funcionarioRepository.listarFuncionarios(search).then(response => {
        this.filteredList = this.funcionarios = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    },
    editRow (id) {
      this.$router.push({ name: 'FuncionariosCadastro', query: { id: id } })
    },
    deleteRow (funcionario) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover funcionário',
        text: `Deseja deletar o funcionário ${funcionario.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarFuncionario(funcionario)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Funcionário removido com sucesso!'
          }).then( () => {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarFuncionario (funcionario) {
      this.isLoading = true
      funcionarioRepository.deletarFuncionario(funcionario.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    onSearch () {
      let filterKey = this.searchText && this.searchText.length > 0 && this.searchText.toLowerCase()
      let list = [...this.funcionarios]
      console.log(list)
      if (this.searchText == '') {
       this.filteredList = list
      } else {
        let rest = list.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
        this.filteredList = [...rest]
      }
      if(this.filteredList.length == 0) {
        this.fetch(filterKey)
      }
    }
  }
}
</script>

<style>

</style>